(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
module.exports = {
	"aliceblue": [240, 248, 255],
	"antiquewhite": [250, 235, 215],
	"aqua": [0, 255, 255],
	"aquamarine": [127, 255, 212],
	"azure": [240, 255, 255],
	"beige": [245, 245, 220],
	"bisque": [255, 228, 196],
	"black": [0, 0, 0],
	"blanchedalmond": [255, 235, 205],
	"blue": [0, 0, 255],
	"blueviolet": [138, 43, 226],
	"brown": [165, 42, 42],
	"burlywood": [222, 184, 135],
	"cadetblue": [95, 158, 160],
	"chartreuse": [127, 255, 0],
	"chocolate": [210, 105, 30],
	"coral": [255, 127, 80],
	"cornflowerblue": [100, 149, 237],
	"cornsilk": [255, 248, 220],
	"crimson": [220, 20, 60],
	"cyan": [0, 255, 255],
	"darkblue": [0, 0, 139],
	"darkcyan": [0, 139, 139],
	"darkgoldenrod": [184, 134, 11],
	"darkgray": [169, 169, 169],
	"darkgreen": [0, 100, 0],
	"darkgrey": [169, 169, 169],
	"darkkhaki": [189, 183, 107],
	"darkmagenta": [139, 0, 139],
	"darkolivegreen": [85, 107, 47],
	"darkorange": [255, 140, 0],
	"darkorchid": [153, 50, 204],
	"darkred": [139, 0, 0],
	"darksalmon": [233, 150, 122],
	"darkseagreen": [143, 188, 143],
	"darkslateblue": [72, 61, 139],
	"darkslategray": [47, 79, 79],
	"darkslategrey": [47, 79, 79],
	"darkturquoise": [0, 206, 209],
	"darkviolet": [148, 0, 211],
	"deeppink": [255, 20, 147],
	"deepskyblue": [0, 191, 255],
	"dimgray": [105, 105, 105],
	"dimgrey": [105, 105, 105],
	"dodgerblue": [30, 144, 255],
	"firebrick": [178, 34, 34],
	"floralwhite": [255, 250, 240],
	"forestgreen": [34, 139, 34],
	"fuchsia": [255, 0, 255],
	"gainsboro": [220, 220, 220],
	"ghostwhite": [248, 248, 255],
	"gold": [255, 215, 0],
	"goldenrod": [218, 165, 32],
	"gray": [128, 128, 128],
	"green": [0, 128, 0],
	"greenyellow": [173, 255, 47],
	"grey": [128, 128, 128],
	"honeydew": [240, 255, 240],
	"hotpink": [255, 105, 180],
	"indianred": [205, 92, 92],
	"indigo": [75, 0, 130],
	"ivory": [255, 255, 240],
	"khaki": [240, 230, 140],
	"lavender": [230, 230, 250],
	"lavenderblush": [255, 240, 245],
	"lawngreen": [124, 252, 0],
	"lemonchiffon": [255, 250, 205],
	"lightblue": [173, 216, 230],
	"lightcoral": [240, 128, 128],
	"lightcyan": [224, 255, 255],
	"lightgoldenrodyellow": [250, 250, 210],
	"lightgray": [211, 211, 211],
	"lightgreen": [144, 238, 144],
	"lightgrey": [211, 211, 211],
	"lightpink": [255, 182, 193],
	"lightsalmon": [255, 160, 122],
	"lightseagreen": [32, 178, 170],
	"lightskyblue": [135, 206, 250],
	"lightslategray": [119, 136, 153],
	"lightslategrey": [119, 136, 153],
	"lightsteelblue": [176, 196, 222],
	"lightyellow": [255, 255, 224],
	"lime": [0, 255, 0],
	"limegreen": [50, 205, 50],
	"linen": [250, 240, 230],
	"magenta": [255, 0, 255],
	"maroon": [128, 0, 0],
	"mediumaquamarine": [102, 205, 170],
	"mediumblue": [0, 0, 205],
	"mediumorchid": [186, 85, 211],
	"mediumpurple": [147, 112, 219],
	"mediumseagreen": [60, 179, 113],
	"mediumslateblue": [123, 104, 238],
	"mediumspringgreen": [0, 250, 154],
	"mediumturquoise": [72, 209, 204],
	"mediumvioletred": [199, 21, 133],
	"midnightblue": [25, 25, 112],
	"mintcream": [245, 255, 250],
	"mistyrose": [255, 228, 225],
	"moccasin": [255, 228, 181],
	"navajowhite": [255, 222, 173],
	"navy": [0, 0, 128],
	"oldlace": [253, 245, 230],
	"olive": [128, 128, 0],
	"olivedrab": [107, 142, 35],
	"orange": [255, 165, 0],
	"orangered": [255, 69, 0],
	"orchid": [218, 112, 214],
	"palegoldenrod": [238, 232, 170],
	"palegreen": [152, 251, 152],
	"paleturquoise": [175, 238, 238],
	"palevioletred": [219, 112, 147],
	"papayawhip": [255, 239, 213],
	"peachpuff": [255, 218, 185],
	"peru": [205, 133, 63],
	"pink": [255, 192, 203],
	"plum": [221, 160, 221],
	"powderblue": [176, 224, 230],
	"purple": [128, 0, 128],
	"rebeccapurple": [102, 51, 153],
	"red": [255, 0, 0],
	"rosybrown": [188, 143, 143],
	"royalblue": [65, 105, 225],
	"saddlebrown": [139, 69, 19],
	"salmon": [250, 128, 114],
	"sandybrown": [244, 164, 96],
	"seagreen": [46, 139, 87],
	"seashell": [255, 245, 238],
	"sienna": [160, 82, 45],
	"silver": [192, 192, 192],
	"skyblue": [135, 206, 235],
	"slateblue": [106, 90, 205],
	"slategray": [112, 128, 144],
	"slategrey": [112, 128, 144],
	"snow": [255, 250, 250],
	"springgreen": [0, 255, 127],
	"steelblue": [70, 130, 180],
	"tan": [210, 180, 140],
	"teal": [0, 128, 128],
	"thistle": [216, 191, 216],
	"tomato": [255, 99, 71],
	"turquoise": [64, 224, 208],
	"violet": [238, 130, 238],
	"wheat": [245, 222, 179],
	"white": [255, 255, 255],
	"whitesmoke": [245, 245, 245],
	"yellow": [255, 255, 0],
	"yellowgreen": [154, 205, 50]
};
},{}],2:[function(require,module,exports){
(function (global){
"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/*jshint esversion: 6 */

function colorcolor(color) {
	var newColor = arguments.length <= 1 || arguments[1] === undefined ? "rgba" : arguments[1];
	var calculateOpacity = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

	color = color.toLowerCase();
	newColor = newColor.toLowerCase();
	var returnedColor = color;
	var namedColor = require('color-name');
	var r, g, b, a;
	var roundTo = 4;
	var colorDefinitions = {
		rgb: {
			re: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
			example: ["rgb(123, 234, 45)", "rgb(255,234,245)"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1], 10), parseInt(bits[2], 10), parseInt(bits[3], 10), 1];
			}
		},
		rgba: {
			re: /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d+(?:\.\d+)?|\.\d+)\s*\)/,
			example: ["rgba(123, 234, 45, 1)", "rgba(255,234,245, 0.5)"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1], 10), parseInt(bits[2], 10), parseInt(bits[3], 10), parseFloat(bits[4])];
			}
		},
		hex: {
			re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
			example: ["00ff00", "336699"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1], 16), parseInt(bits[2], 16), parseInt(bits[3], 16), 1];
			}
		},
		hex3: {
			re: /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
			example: ["fb0", "f0f"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1] + bits[1], 16), parseInt(bits[2] + bits[2], 16), parseInt(bits[3] + bits[3], 16), 1];
			}
		},
		hexa: {
			re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
			example: ["00ff00ff", "336699a0"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1], 16), parseInt(bits[2], 16), parseInt(bits[3], 16), parseInt(bits[4], 16) / 255];
			}
		},
		hex4a: {
			re: /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
			example: ["fb0f", "f0f8"],
			toRGBA: function toRGBA(bits) {
				return [parseInt(bits[1] + bits[1], 16), parseInt(bits[2] + bits[2], 16), parseInt(bits[3] + bits[3], 16), parseInt(bits[4] + bits[4], 16) / 255];
			}
		},
		hsl: {
			re: /^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/,
			example: ["hsl(120, 100%, 25%)", "hsl(0, 100%, 50%)"],
			toRGBA: function toRGBA(bits) {
				bits[4] = 1;
				var rgba = hslToRgb(bits);
				return [rgba.r, rgba.g, rgba.b, rgba.a];
			}
		},
		hsla: {
			re: /^hsla\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*(\d+(?:\.\d+)?|\.\d+)\s*\)/,
			example: ["hsla(120, 100%, 25%, 1)", "hsla(0, 100%, 50%, 0.5)"],
			toRGBA: function toRGBA(bits) {
				var rgba = hslToRgb(bits);
				return [rgba.r, rgba.g, rgba.b, rgba.a];
			}
		},
		hsv: {
			re: /^hsv\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/,
			example: ["hsv(120, 100%, 25%)", "hsv(0, 100%, 50%)"],
			toRGBA: function toRGBA(bits) {
				var rgb = hsvToRgb(bits);
				return [rgb.r, rgb.g, rgb.b, 1];
			}
		},
		hsb: {
			re: /^hsb\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/,
			example: ["hsb(120, 100%, 25%)", "hsb(0, 100%, 50%)"],
			toRGBA: function toRGBA(bits) {
				var rgb = hsvToRgb(bits);
				return [rgb.r, rgb.g, rgb.b, 1];
			}
		}
	};

	// If this is a named color, convert it to hex
	if (namedColor.hasOwnProperty(color)) {
		color = namedColor[color];
		color.forEach(function (piece, index) {
			"use strict";

			color[index] = ("0" + piece.toString(16)).slice(-2);
		});
		color = "#" + color.join('');
	}

	// Search the color definitions for a match
	for (var colorDefinition in colorDefinitions) {
		var re = colorDefinitions[colorDefinition].re;
		var processor = colorDefinitions[colorDefinition].toRGBA;
		var bits = re.exec(color);
		if (bits) {
			var channels = processor(bits);
			r = channels[0];
			g = channels[1];
			b = channels[2];
			a = +(Math.round(channels[3] + ("e+" + roundTo)) + ("e-" + roundTo));
		}
	}
	r = Math.round(r < 0 || isNaN(r) ? 0 : r > 255 ? 255 : r);
	g = Math.round(g < 0 || isNaN(g) ? 0 : g > 255 ? 255 : g);
	b = Math.round(b < 0 || isNaN(b) ? 0 : b > 255 ? 255 : b);
	a = a < 0 || isNaN(a) ? 0 : a > 1 ? 1 : a;

	switch (newColor) {
		case "hex":
			returnedColor = "#" + ("0" + r.toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + b.toString(16)).slice(-2);
			break;
		case "hexa":
			if (calculateOpacity) {
				var _calculateOpacityFrom = calculateOpacityFromWhite(r, g, b, a);

				var _calculateOpacityFrom2 = _slicedToArray(_calculateOpacityFrom, 4);

				r = _calculateOpacityFrom2[0];
				g = _calculateOpacityFrom2[1];
				b = _calculateOpacityFrom2[2];
				a = _calculateOpacityFrom2[3];
			}
			returnedColor = "#" + ("0" + r.toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + b.toString(16)).slice(-2) + ("0" + Math.round(255 * a).toString(16)).slice(-2);
			break;
		case "hsl":
			var hsl = rgbToHsl({ "r": r, "g": g, "b": b });
			returnedColor = "hsl(" + hsl.h + "," + hsl.s + "%," + hsl.l + "%)";
			break;
		case "hsla":
			if (calculateOpacity) {
				var _calculateOpacityFrom3 = calculateOpacityFromWhite(r, g, b, a);

				var _calculateOpacityFrom4 = _slicedToArray(_calculateOpacityFrom3, 4);

				r = _calculateOpacityFrom4[0];
				g = _calculateOpacityFrom4[1];
				b = _calculateOpacityFrom4[2];
				a = _calculateOpacityFrom4[3];
			}
			var hsla = rgbToHsl({ "r": r, "g": g, "b": b, "a": a });
			returnedColor = "hsla(" + hsla.h + "," + hsla.s + "%," + hsla.l + "%," + hsla.a + ")";
			break;
		case "hsb":
			/* Same as `hsv` */
			var hsb = rgbToHsv({ "r": r, "g": g, "b": b });
			returnedColor = "hsb(" + hsb.h + "," + hsb.s + "%," + hsb.v + "%)";
			break;
		case "hsv":
			var hsv = rgbToHsv({ "r": r, "g": g, "b": b });
			returnedColor = "hsv(" + hsv.h + "," + hsv.s + "%," + hsv.v + "%)";
			break;
		case "rgb":
			returnedColor = "rgb(" + r + "," + g + "," + b + ")";
			break;
		case "rgba":
		/* falls through */
		default:
			if (calculateOpacity) {
				var _calculateOpacityFrom5 = calculateOpacityFromWhite(r, g, b, a);

				var _calculateOpacityFrom6 = _slicedToArray(_calculateOpacityFrom5, 4);

				r = _calculateOpacityFrom6[0];
				g = _calculateOpacityFrom6[1];
				b = _calculateOpacityFrom6[2];
				a = _calculateOpacityFrom6[3];
			}
			returnedColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";
			break;
	}

	return returnedColor;
}

function calculateOpacityFromWhite(r, g, b, a) {
	"use strict";

	var min = 0;
	a = (255 - (min = Math.min(r, g, b))) / 255;
	r = (0 || (r - min) / a).toFixed(0);
	g = (0 || (g - min) / a).toFixed(0);
	b = (0 || (b - min) / a).toFixed(0);
	a = parseFloat(a.toFixed(4));

	return [r, g, b, a];
}

function hslToRgb(bits) {
	var rgba = {},
	    hsl = {
		h: bits[1] / 360,
		s: bits[2] / 100,
		l: bits[3] / 100,
		a: parseFloat(bits[4])
	};
	if (hsl.s === 0) {
		var v = 255 * hsl.l;
		rgba = {
			r: v,
			g: v,
			b: v,
			a: hsl.a
		};
	} else {
		var q = hsl.l < 0.5 ? hsl.l * (1 + hsl.s) : hsl.l + hsl.s - hsl.l * hsl.s;
		var p = 2 * hsl.l - q;
		rgba.r = hueToRgb(p, q, hsl.h + 1 / 3) * 255;
		rgba.g = hueToRgb(p, q, hsl.h) * 255;
		rgba.b = hueToRgb(p, q, hsl.h - 1 / 3) * 255;
		rgba.a = hsl.a;
	}

	return rgba;
}

function rgbToHsl(rgba) {
	rgba.r = rgba.r / 255;
	rgba.g = rgba.g / 255;
	rgba.b = rgba.b / 255;
	var max = Math.max(rgba.r, rgba.g, rgba.b),
	    min = Math.min(rgba.r, rgba.g, rgba.b),
	    hsl = [],
	    d;
	hsl.a = rgba.a;
	hsl.l = (max + min) / 2;
	if (max === min) {
		hsl.h = 0;
		hsl.s = 0;
	} else {
		d = max - min;
		hsl.s = hsl.l >= 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case rgba.r:
				hsl.h = (rgba.g - rgba.b) / d + (rgba.g < rgba.b ? 6 : 0);
				break;
			case rgba.g:
				hsl.h = (rgba.b - rgba.r) / d + 2;
				break;
			case rgba.b:
				hsl.h = (rgba.r - rgba.g) / d + 4;
				break;
		}
		hsl.h /= 6;
	}
	hsl.h = parseInt((hsl.h * 360).toFixed(0), 10);
	hsl.s = parseInt((hsl.s * 100).toFixed(0), 10);
	hsl.l = parseInt((hsl.l * 100).toFixed(0), 10);

	return hsl;
}

function hsvToRgb(bits) {
	var rgb = {},
	    hsv = {
		h: bits[1] / 360,
		s: bits[2] / 100,
		v: bits[3] / 100
	},
	    i = Math.floor(hsv.h * 6),
	    f = hsv.h * 6 - i,
	    p = hsv.v * (1 - hsv.s),
	    q = hsv.v * (1 - f * hsv.s),
	    t = hsv.v * (1 - (1 - f) * hsv.s);
	switch (i % 6) {
		case 0:
			rgb.r = hsv.v;
			rgb.g = t;
			rgb.b = p;
			break;
		case 1:
			rgb.r = q;
			rgb.g = hsv.v;
			rgb.b = p;
			break;
		case 2:
			rgb.r = p;
			rgb.g = hsv.v;
			rgb.b = t;
			break;
		case 3:
			rgb.r = p;
			rgb.g = q;
			rgb.b = hsv.v;
			break;
		case 4:
			rgb.r = t;
			rgb.g = p;
			rgb.b = hsv.v;
			break;
		case 5:
			rgb.r = hsv.v;
			rgb.g = p;
			rgb.b = q;
			break;
	}
	rgb.r = rgb.r * 255;
	rgb.g = rgb.g * 255;
	rgb.b = rgb.b * 255;

	return rgb;
}

function rgbToHsv(rgba) {
	rgba.r = toPercent(parseInt(rgba.r, 10) % 256, 256);
	rgba.g = toPercent(parseInt(rgba.g, 10) % 256, 256);
	rgba.b = toPercent(parseInt(rgba.b, 10) % 256, 256);
	var max = Math.max(rgba.r, rgba.g, rgba.b),
	    min = Math.min(rgba.r, rgba.g, rgba.b),
	    d = max - min,
	    hsv = {
		"h": 0,
		"s": max === 0 ? 0 : d / max,
		"v": max
	};
	if (max !== min) {
		switch (max) {
			case rgba.r:
				hsv.h = (rgba.g - rgba.b) / d + (rgba.g < rgba.b ? 6 : 0);
				break;
			case rgba.g:
				hsv.h = (rgba.b - rgba.r) / d + 2;
				break;
			case rgba.b:
				hsv.h = (rgba.r - rgba.g) / d + 4;
				break;
		}
		hsv.h /= 6;
	}
	hsv.h = parseInt((hsv.h * 360).toFixed(0), 10);
	hsv.s = parseInt((hsv.s * 100).toFixed(0), 10);
	hsv.v = parseInt((hsv.v * 100).toFixed(0), 10);

	return hsv;
}

function hueToRgb(p, q, t) {
	if (t < 0) {
		t += 1;
	}
	if (t > 1) {
		t -= 1;
	}
	if (t < 1 / 6) {
		return p + (q - p) * 6 * t;
	}
	if (t < 1 / 2) {
		return q;
	}
	if (t < 2 / 3) {
		return p + (q - p) * ((2 / 3 - t) * 6);
	}

	return p;
}

function toPercent(amount, limit) {
	return amount / limit;
}

module.exports = colorcolor;
global.colorcolor = module.exports; /* ew */

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"color-name":1}]},{},[2]);
